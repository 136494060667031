<template>
  <div class="content-main-header">
    <wrapper class="wrapper">
      <div class="breadcrumb-info">
        <ul class="list-items">
          <li>{{ module }}</li>
        </ul>
      </div>
      <h1 class="header-title">
        {{ pageTitle }}
      </h1>
      <p class="header-description">
        {{ description }}
      </p>
      <img
        v-if="headerImage"
        alt
        class="header-img"
        :src="headerImage"
      >
    </wrapper>
  </div>
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
    module: {
      type: String,
      required: true,
    },
    pageTitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    headerImage: {
      type: String,
      default: null,
    },
  },
}

</script>

<style lang="sass" >
.content-main-header
  background: linear-gradient(106.57deg, #2B4A99 2.65%, #2B5999 98.74%)
  height: 100%
  position: relative
  margin-top: 70px

  &:before
    content: ''
    background: url('~@/assets/img/left.svg') no-repeat
    position: absolute
    bottom: 0
    left: 0
    z-index: 0
    width: 100%
    height: 145px

  &:after
    content: ''
    background: url('~@/assets/img/right.svg') no-repeat
    position: absolute
    top: 0
    right: 0
    z-index: 0
    width: 743px
    height: 145px

  +mq-l--mf
    position: relative
    height: 224px

  .wrapper
    position: relative
    height: 310px

  +mq-l--mf
    position: relative
    height: 224px

  .breadcrumb-info
    display: flex
    justify-content: flex-start
    align-items: center

    .list-items
      display: flex
      font-size: 14px
      text-transform: uppercase
      line-height: 20px
      font-weight: 600
      align-items: center
      letter-spacing: 0.4px
      color: #F8CB7F !important
      padding: 5px 0
      margin-top: 20px

      +mq-m--mf
        width: 100%

  .header-title
    line-height: 56px
    display: flex
    align-items: center
    color: $color-white
    margin-top: 15px

  .header-description
    line-height: 24px
    letter-spacing: 0.3px
    display: flex
    align-items: center
    color: $color-white
    max-width: 450px
    margin-top: 16px
    margin-bottom: 40px

    +mq-m--mf
      margin-top: 5px
      max-width: 400px

    +mq-l--mf
      margin-top: 5px
      max-width: 450px

  .header-img
    position: absolute
    width: 301px
    height: 269px
    right: 0
    top: 50px
    z-index: 1
    visibility: hidden

    +mq-m--mf
      visibility: visible
      top: 50px

    +mq-l--mf
      visibility: visible
      top: 23px

</style>
